import ImageOrientationReseter from '../../../shared/javascripts/helpers/image_orientation_reseter';
import jstz from 'jstz';
import Checkout from './checkout.js.erb';
import Timezones from './timezones.js.erb';

export default class SignUpForm {
  constructor() {
    this.initializeAvatarUploader();
    this.initializeCheckout();
    this.preSelectTimezone();
    this.initializePlansChooser();
  }

  preSelectTimezone() {
    const timezone = Timezones.info[jstz.determine().name()];
    let timezoneSelect = document.getElementById('user_timezone');

    if(!timezone) {
      timezoneSelect.value = 'Eastern Time (US & Canada)';
    } else {
      timezoneSelect.value = timezone;
    }
  }

  initializeCheckout() {
    new Checkout();
  }

  initializePlansChooser() {
    let planElements = document.querySelectorAll('.plan');

    for (let planElement of planElements) {
      planElement.addEventListener('click', function() {
        let previousPlan = document.querySelector('.plan.active');
        let radioBtn = planElement.querySelector('input');
        let currentPlan = planElement.dataset.plan;
        let previousLegend = document.querySelector('.billing-legend.active');
        let currentLegend = document.querySelector(`.billing-legend.${currentPlan}-terms`);

        previousLegend.classList.remove('active');
        currentLegend.classList.add('active');

        if (previousPlan) {
          previousPlan.classList.remove('active');
        }

        radioBtn.checked = true;
        planElement.classList.add('active');
      });
    }
  }

  initializeAvatarUploader() {
    let fileInput = document.querySelector('input[type="file"]');
    if (fileInput === null) { return; }

    let avatarPreviewContainer = document.querySelector('.file-area');
    let avatarPreviewMessage = document.querySelector('.file-area .message');
    let imgReader = new FileReader();

    let oldBackgroundImageValue = avatarPreviewContainer.style['background-image'];
    let oldBackgroundSizeValue = avatarPreviewContainer.style['background-size'];
    let oldBorderColor = avatarPreviewContainer.style['border-color'];

    fileInput.addEventListener('change', function() {
      if (this.files.length > 0) {
        let file = this.files[0];

        ImageOrientationReseter.getOrientation(file, function(orientation) {
          imgReader.onloadend = function() {
            if (orientation > 0) {
              ImageOrientationReseter.call(imgReader.result, orientation, function(fixedImage) {
                avatarPreviewContainer.style['background-image'] = `url(${fixedImage})`;
              });
            } else {
              avatarPreviewContainer.style['background-image'] = `url(${imgReader.result})`;
            }

            avatarPreviewContainer.style['background-size'] = 'cover';
            avatarPreviewContainer.style['border-color'] = 'transparent';
            avatarPreviewMessage.style['display'] = 'none';
          };

          imgReader.readAsDataURL(file);
        });
      } else {
        avatarPreviewContainer.style['background-image'] = oldBackgroundImageValue;
        avatarPreviewContainer.style['background-size'] = oldBackgroundSizeValue;
        avatarPreviewContainer.style['border-color'] = oldBorderColor;
        avatarPreviewMessage.style['display'] = 'block';
      }
    });
  }
}
