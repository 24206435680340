export default class LoginForm {
  constructor() {
    this.initializeShowPasswordCheckbox();
  }

  initializeShowPasswordCheckbox() {
    let showPasswordCheckbox = document.getElementById('show-password-checkbox');
    let passwordField = document.querySelector('input[type="password"]');

    showPasswordCheckbox.addEventListener('change', function() {
      if (this.checked) {
        passwordField.type = 'text';
      } else {
        passwordField.type = 'password';
      }
    });
  }
}
