import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';

import 'jquery';
import 'popper.js';
import 'bootstrap';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import ComponentsLoader from './components_loader';

document.addEventListener("turbolinks:load", function() {
  new ComponentsLoader();
});
