import Stripe from 'stripe';
import Webfont from './webfont';

const stripe_publishable_key =
  document.currentScript.dataset.stripePublishableKey;

export default class StripeHelper {
  static get defaultStripeOptions() {
    return new Object({
      style: {
        base: {
          color: '#10253f',
          fontFamily: 'BrandonGrotesque',
          fontSize: '16px',
          fontWeight: 'normal',
          '::placeholder': {
            color: '#77818c'
          }
        }
      },
      classes: {
        base: 'stripe-control'
      }
    });
  }

  constructor(targetForm, hiddenInputName) {
    this.stripe = Stripe(stripe_publishable_key);
    this.targetForm = targetForm;
    this.hiddenInputName = hiddenInputName;
    this.errorsContainer = document.getElementById('card-errors');

    this.elements = this.stripe.elements({
      fonts: [
        {
          family: 'BrandonGrotesque',
          src: Webfont.toBase64URL()
        }
      ]
    });

    this.renderPaymentForm();
    this.handleFormSubmit();
  }

  renderPaymentForm() {
    this.renderCardNumberField();
    this.renderExpirationField();
    this.renderCVCField();
  }

  renderCardNumberField() {
    this.card = this.elements.create(
      'cardNumber',
      StripeHelper.defaultStripeOptions
    );
    this.card.mount('#card-element');

    this.card.addEventListener(
      'change',
      function (event) {
        if (event.error) {
          this.errorsContainer.textContent = event.error.message;
        } else {
          this.errorsContainer.textContent = '';
        }
      }.bind(this)
    );
  }

  renderExpirationField() {
    this.elements
      .create('cardExpiry', StripeHelper.defaultStripeOptions)
      .mount('#expiration-element');
  }

  renderCVCField() {
    this.elements
      .create('cardCvc', StripeHelper.defaultStripeOptions)
      .mount('#cvc-element');
  }

  handleFormSubmit() {
    this.targetForm.addEventListener(
      'submit',
      function (evt) {
        evt.preventDefault();

        this.stripe.createToken(this.card).then(
          function (result) {
            if (result.error) {
              this.errorsContainer = result.error.message;
            } else {
              this.stripeTokenHandler(result.token);
            }
          }.bind(this)
        );
      }.bind(this)
    );
  }

  stripeTokenHandler(token) {
    let stripeTokenHiddenInput = document.createElement('input');

    stripeTokenHiddenInput.setAttribute('type', 'hidden');
    stripeTokenHiddenInput.setAttribute('name', this.hiddenInputName);
    stripeTokenHiddenInput.setAttribute('value', token.id);

    this.targetForm.appendChild(stripeTokenHiddenInput);
    this.targetForm.submit();
  }
}
