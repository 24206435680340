import LoginForm from './components/login_form';
import SignUpForm from './components/sign_up_form';

export default class ComponentsLoader {
  constructor() {
    this.loadComponents();
  }

  loadComponents() {
    this.loadComponentByPath(/^\/login+/, function() {
      new LoginForm();
    });

    this.loadComponentByPath(/^\/signup+/, function() {
      new SignUpForm();
    });
  }

  loadComponentByPath(condition, loader) {
    if (window.location.pathname.match(condition)) {
      loader();
    }
  }
}
