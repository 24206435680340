import Stripe from 'stripe';
import StripeHelper from '../../../shared/javascripts/helpers/stripe_helper.js';

export default class Checkout {
  constructor() {
    let targetForm = document.getElementById('new-account-form');
    let hiddenInputName = 'user[stripe_token]';

    new StripeHelper(targetForm, hiddenInputName);
  }
}
